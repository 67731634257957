<template>
  <div class="seat-details">
    <span class="fa fa-chair pr-1 seat-icon" />
    <span class="seat-details__text">
      <div v-if="selectedContractName" class="spot-name">{{ selectedContractName }}</div>
      {{ [
        formattedDaysPerWeek,
        daysOfWeek,
        hoursFromTo,
        formattedCost
      ].filter(Boolean).join('&nbsp; • &nbsp;') }}
      <div class="spot-starting-at">{{ startingAt }}</div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'seat-details',
  props: {
    selectedContractName: {
      type: String,
      default: null,
    },
    availableAt: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    costFrequency: {
      type: String,
      required: true,
    },
    daysPerWeek: {
      type: Number,
      default: null,
    },
    daysOfWeek: {
      type: String,
      default: null,
    },
    hoursFromTo: {
      type: String,
      default: null,
    },
  },
  methods: {
    formattedDate(dateString) {
      return this.$moment(dateString).utc().format('MMM D, YYYY');
    },
  },
  computed: {
    startingAt() {
      return this.availableAt ? `Starting ${this.formattedDate(this.availableAt)}` : '';
    },
    formattedCost() {
      let suffix = this.costFrequency;
      if (['month', 'months'].includes(this.costFrequency)) {
        suffix = 'mo';
      } else if (['week', 'weeks'].includes(this.costFrequency)) {
        suffix = 'wk';
      } else if (['year', 'years'].includes(this.costFrequency)) {
        suffix = 'yr';
      }
      return `$${this.cost / 100} / ${suffix}`;
    },
    formattedDaysPerWeek() {
      return this.daysPerWeek ? `${this.daysPerWeek} ${this.daysPerWeek === 1 ? 'day' : 'days'} / wk` : '';
    },
  },
};
</script>

<style scoped>
.seat-icon {
  font-size: 17px;
  margin-right: 4px;
  position: relative;
  top: 0.5em;
  color: var(--periwinkle-20);
}

.spot-name {
  font-weight: 400;
  font-size: 20px;
}

.seat-details {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-bottom: calc(var(--grid-unit) * 2);
}

.seat-details__text {
  line-height: 1.6;
}

.spot-starting-at {
  font-weight: 500;
  color: var(--periwinkle-50);
}
</style>
