const formatAge = (ageMonths, ageYears) => {
  let years = Math.floor(ageMonths / 12) + ageYears;
  if (ageMonths < 0) {
    years = Math.ceil(ageMonths / 12) + ageYears;
  }
  const months = ageMonths % 12;
  return { years, months };
};

export default formatAge;
