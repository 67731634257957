<template>
  <span
    class="time-range-input"
    :class="{
      'time-range-input--error': hasError,
      'time-range-input--disabled': disabled,
    }"
  >
    <time-input
      v-model="min"
      :format="format"
      :error="localError"
      :placeholder="placeholder.from"
      :step="step"
      :disabled="disabled"
      :required="required"
      :force-update="forceUpdate"
    />
      <span class="time-range-input__preposition text-color-50">
        to
      </span>
    <time-input
      v-model="max"
      :format="format"
      :error="localError"
      :placeholder="placeholder.to"
      :step="step"
      without-icon
      :disabled="disabled"
      :required="required"
      :force-update="forceUpdate"
    />
  </span>
</template>

<script>
import moment from 'moment-timezone';
import TimeInput from './time-input.vue';

export default {
  components: { TimeInput },
  props: {
    format: {
      type: String,
      default: 'h:mm a',
      validator(v) {
        return ['h:mm a', 'h:mma', 'HH:mm'].includes(v);
      },
    },
    value: {
      type: Object,
      validator: (value) => Object.prototype.hasOwnProperty.call(value, 'from') && Object.prototype.hasOwnProperty.call(value, 'to'),
      default() {
        return {
          from: undefined,
          to: undefined,
        };
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Object,
      validator: (value) => Object.prototype.hasOwnProperty.call(value, 'from') && Object.prototype.hasOwnProperty.call(value, 'to'),
      default() {
        return {
          from: undefined,
          to: undefined,
        };
      },
    },
    step: Number,
    disabled: Boolean,
    overnights: Boolean,
    forceUpdate: Boolean,
  },
  data() {
    return {
      localError: this.error,
    };
  },
  mounted() {
    if (this.value?.from && this.value?.to && !this.isValidMaxMin(this.value)) {
      this.emitError(true);
    }
  },
  computed: {
    min: {
      get() {
        return this.value.from;
      },
      set(value) {
        if (value !== this.value.from) {
          this.emitValue({ from: value, to: this.max });
        }
      },
    },
    max: {
      get() {
        return this.value.to;
      },
      set(value) {
        if (value !== this.value.to) {
          this.emitValue({ from: this.min, to: value });
        }
      },
    },
    hasError() {
      return this.error || this.localError;
    },
  },
  methods: {
    isValidMaxMin(value) {
      if (!value.from || !value.to) {
        // only return false if we have values to check
        return true;
      }

      if (this.overnights) {
        return true;
      }

      const from = moment(value.from, this.format);
      const to = moment(value.to, this.format);

      return from.isBefore(to);
    },
    emitValue(value) {
      this.$emit('input', value);
      const error = ([value.from, value.to].includes(undefined))
        ? this.required
        : !this.isValidMaxMin(value);
      this.emitError(error);
    },
    emitError(error) {
      this.localError = error;
      this.$emit('error', error);
    },
  },
};
</script>

<style lang="scss" scoped>
  .time-range-input {
    display: inline-flex;
    align-items: baseline;
    justify-content: space-between;
    width: calc(var(--grid-unit) * 43);
    background: var(--gray-0);
    border: 1px solid var(--gray-80);
    border-radius: var(--radius);

    @media screen and (max-width: 360px) {
      width: calc(var(--grid-unit) * 37);

      &::v-deep .custom-icon {
        display: none;
      }
    }

    &::v-deep .time-picker__input {
      border: 0;

      @media screen and (max-width: 360px) {
        width: calc(var(--grid-unit) * 16);
        padding-left: calc(var(--grid-unit) * 2);
      }

      &--error {
        box-shadow: none;
      }
    }

    &--error {
      color: var(--rust-50);
      border-color: var(--rust-50);
      box-shadow: 0 0 0 2px var(--rust-0);
    }

    &--disabled {
      color: var(--gray-20);
      border-color: var(--gray-20);
    }

    &__preposition {
      position: relative;
      width: 18px;
      margin: 0 var(--grid-unit);
    }

    &::v-deep {
      label {
        margin: 0;
      }

      .k-input {
        border: none;
        box-shadow: none;
      }
    }

    &:focus-within {
      box-shadow: 0 0 0 2px var(--input-focus-color);
    }
  }
</style>
