export default (birthdate) => {
  if (Object.prototype.toString.call(birthdate) === '[object Date]') {
    if (Number.isNaN(birthdate)) {
      return 0;
    }
  } else {
    return 0;
  }

  const now = new Date();
  let months = 12 * (now.getUTCFullYear() - birthdate.getUTCFullYear());
  months += (now.getUTCMonth() - birthdate.getUTCMonth());
  if (now.getUTCDate() < birthdate.getUTCDate()) {
    months -= 1;
  }

  return months;
};
