<template>
  <div>
    <p class="font-body-normal align-center">
      Which days of the week are you interested in?
    </p>
    <k-form-field name="daysOfWeek">
      <div class="select-days__checkbox-inline-wrapper">
        <k-checkbox
          v-for="day in $options.daysOfWeek"
          :key="day"
          v-model="selectedWeekDays"
          :value="$options.daysOfWeek.indexOf(day)"
        >
          {{day}}
        </k-checkbox>
      </div>
    </k-form-field>
  </div>
</template>

<script>
import KFormField from '@components/forms/form_field.vue';
import KCheckbox from '@components/inputs/checkbox.vue';

export default {
  name: 'select-week-days',
  components: {
    KFormField,
    KCheckbox,
  },
  props: {
    waitlistData: {
      type: Object,
      default() {
        return {};
      },
    },
    tourData: {
      type: Object,
      default() {
        return {};
      },
    },
    bus: {
      type: Object,
      required: true,
    },
    submitStep: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },

  daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],

  data() {
    return {
      selectedWeekDays: this.waitlistData.selectedWeekDays || this.tourData.selectedWeekDays || [],
    };
  },
  created() {
    this.bus.$on('on-next-step', this.handleNext);
  },
  beforeDestroy() {
    this.bus.$off('on-next-step', this.handleNext);
  },
  watch: {
    selectedWeekDays() {
      this.onChange(this.$data);
    },
  },
  methods: {
    handleNext() {
      this.submitStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.select-days {
  &__checkbox-inline-wrapper {
    margin-left: 9rem;

    .k-checkbox{
      padding-bottom: 10px;
    }
  }
}

.align-center {
  margin-bottom: 20px;
  text-align: center;
}
</style>
