import moment from 'moment-timezone';

export default (name, paymentRequests) => {
  let csv = 'Date,Amount,Type,Child,Status,Invoice UID\n';
  paymentRequests.forEach((row) => {
    const rowData = [
      row.scehduledAt || row.createdAt,
      row.amount,
      row.category,
      row.childrenNames?.join(', '),
      row.aasmState,
      row.invoiceUid,
    ];

    csv += `${rowData.join(',')}\n`;
  });

  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  anchor.target = '_blank';
  anchor.download = `${name}-payments-${moment().format('YYYY-MM-DD')}.csv`;
  anchor.click();
};
