import {
  dependentAgeGroup,
  ageGroupProgramSchedules,
  updateOrRemoveWaitlistSpot,
  passOrDeclineSeatOffer,
  providerWaitlistFees,
  validateProviderWaitlistCoupon,
  validateSubsidiesDiscount,
  checkForProviderQuestionsDiscount,
  checkForProviderSubsidiesDiscount,
  findCenterInfo,
  findCenterQuestions,
  findCenterSubsidies,
  checkForProviderCoupons,
  fetchSeatOfferDetails,
  fetchSeatOffersForParent,
  sendOffersMessage,
  requestPaperwork,
  updateWaitlistSpotAnswers,
  acceptSeatOffer,
  fetchAgeGroups,
} from './legup.service';

export default {
  namespaced: true,
  state: {
    dependentAgeGroup: null,
    waitlistFees: {},
    couponInfo: {},
    centerInfo: {},
    centerQuestions: [],
    centerSubsidies: [],
    providerCoupons: [],
    seatOfferDetails: {},
    seatOffersForParent: [],
    seatOfferMessageParams: {},
    declineOfferInfo: {},
    subsidyDiscount: false,
    ageGroups: [],
  },
  mutations: {
    setDeclineOfferInfo(state, payload) {
      state.declineOfferInfo = payload;
    },
    setDependentAgeGroup: (state, { ageGroupId }) => {
      state.dependentAgeGroup = ageGroupId;
    },
    setSubsidyDiscount: (state, { subsidyDiscount }) => {
      state.subsidyDiscount = subsidyDiscount;
    },
    setWaitlistFees: (state, { waitlistFees }) => {
      state.waitlistFees = waitlistFees;
    },
    setCouponInfo: (state, { couponInfo }) => {
      state.couponInfo = couponInfo;
    },
    setCenterInfo: (state, { centerInfo }) => {
      state.centerInfo = centerInfo;
    },
    setCenterQuestions: (state, { centerQuestions }) => {
      state.centerQuestions = centerQuestions;
    },
    setCenterSubsidies: (state, { centerSubsidies }) => {
      state.centerSubsidies = centerSubsidies;
    },
    setProviderCoupons: (state, { providerCoupons }) => {
      state.providerCoupons = providerCoupons;
    },
    setSeatOfferDetails: (state, { seatOfferDetails }) => {
      state.seatOfferDetails = seatOfferDetails;
    },
    setSeatOffersForParent: (state, { seatOffersForParent }) => {
      state.seatOffersForParent = seatOffersForParent;
    },
    setSeatOfferMessageParams: (state, { seatOfferMessageParams }) => {
      state.seatOfferMessageParams = seatOfferMessageParams;
    },
    setAgeGroups: (state, { ageGroups }) => {
      state.ageGroups = ageGroups;
    },
  },
  getters: {
    manageWaitlistUrl(state) {
      return `/manage_legup_waitlist/${state.parentId}`;
    },
    dependentAgeGroup(state) {
      return state.dependentAgeGroup;
    },
    ageGroupProgramSchedules(state) {
      return state.ageGroupProgramSchedules;
    },
    updateOrRemoveWaitlistSpot(state) {
      return state.updateOrRemoveWaitlistSpot;
    },
    passOrDeclineSeatOffer(state) {
      return state.passOrDeclineSeatOffer;
    },
    requestPaperwork(state) {
      return state.requestPaperwork;
    },
    validateSubsidiesDiscount(state) {
      return state.subsidyDiscount;
    },
    providerWaitlistFees(state) {
      return state.waitlistFees;
    },
    validateProviderWaitlistCoupon(state) {
      return state.couponInfo;
    },
    checkForProviderQuestionsDiscount(state) {
      return state.couponInfo;
    },
    checkForProviderSubsidiesDiscount(state) {
      return state.couponInfo;
    },
    findCenterInfo(state) {
      return state.centerInfo;
    },
    findCenterQuestions(state) {
      return state.centerQuestions;
    },
    findCenterSubsidies(state) {
      return state.centerSubsidies;
    },
    checkForProviderCoupons(state) {
      return state.providerCoupons;
    },
    fetchSeatOfferDetails(state) {
      return state.seatOfferDetails;
    },
    getCenterSeatOfferDetails(state) {
      return state.centerSeatOfferDetails;
    },
    getDeclineOfferInfo(state) {
      return state.declineOfferInfo;
    },
  },
  actions: {

    async dependentAgeGroup({ commit }, { centerId, childBirthDate, preferredDate }) {
      const response = await dependentAgeGroup(centerId, childBirthDate, preferredDate);
      if (response?.success) {
        commit('setDependentAgeGroup', { ageGroupId: Number(response.ageGroupId) });
      } else {
        commit('setDependentAgeGroup', { ageGroupId: undefined });
      }
    },

    async ageGroupProgramSchedules({ dispatch }, params) {
      try {
        const programs = await ageGroupProgramSchedules(params);

        return programs;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch age group program schedules', error }, { root: true });
        throw new Error(`ageGroupProgramSchedules failed due to ${error.code}: ${error.message}`);
      }
    },

    async updateOrRemoveWaitlistSpot({ dispatch }, params) {
      try {
        const result = await updateOrRemoveWaitlistSpot(params);
        if (params.remove_from_waitlist && params.provider_removal) {
          dispatch(
            'notifications/addToastSuccess',
            { text: 'Waitlist spot successfully removed.' },
            { root: true },
          );
        }
        return result;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update the waitlist spot. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error();
      }
    },

    async passOrDeclineSeatOffer({ dispatch }, params) {
      try {
        await passOrDeclineSeatOffer(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not complete this offer response. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error(`passOrDeclineSeatOffer failed due to: ${error.code}: ${error.message}`);
      }
    },
    async validateSubsidiesDiscount({ commit, dispatch }, centerId) {
      try {
        const response = await validateSubsidiesDiscount(centerId);
        if (response?.success) {
          commit('setSubsidyDiscount', { subsidyDiscount: response.subsidy_discount });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'There is no subsidy discount.', error }, { root: true });
      }
    },
    async providerWaitlistFees({ commit, dispatch }, params) {
      try {
        const response = await providerWaitlistFees(params);
        if (response?.success) {
          commit('setWaitlistFees', { waitlistFees: response.waitlist_fee });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the waitlist fees', error }, { root: true });
      }
    },
    async validateProviderWaitlistCoupon({ commit, dispatch }, params) {
      try {
        const response = await validateProviderWaitlistCoupon(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not validate the provider coupon', error }, { root: true });
      }
    },
    async checkForProviderQuestionsDiscount({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderQuestionsDiscount(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the provider questions discount', error }, { root: true });
      }
    },
    async checkForProviderSubsidiesDiscount({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderSubsidiesDiscount(params);
        if (response?.success) {
          commit('setCouponInfo', { couponInfo: response });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the provider subsidies discount', error }, { root: true });
      }
    },
    async findCenterInfo({ commit, dispatch }, params) {
      try {
        const response = await findCenterInfo(params);
        commit('setCenterInfo', { centerInfo: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the LegUp center information', error }, { root: true });
      }
    },
    async findCenterQuestions({ commit, dispatch }, params) {
      try {
        const response = await findCenterQuestions(params);
        commit('setCenterQuestions', { centerQuestions: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the center questions', error }, { root: true });
      }
    },
    async findCenterSubsidies({ commit, dispatch }, params) {
      try {
        const response = await findCenterSubsidies(params);
        commit('setCenterSubsidies', { centerSubsidies: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the center subsidies', error }, { root: true });
      }
    },
    async checkForProviderCoupons({ commit, dispatch }, params) {
      try {
        const response = await checkForProviderCoupons(params);
        commit('setProviderCoupons', { providerCoupons: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve provider coupons', error }, { root: true });
      }
    },
    async fetchSeatOfferDetails({ commit, dispatch }, params) {
      try {
        const response = await fetchSeatOfferDetails(params);
        commit('setSeatOfferDetails', { seatOfferDetails: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve offer details', error }, { root: true });
      }
    },
    async fetchSeatOffersForParent({ commit, dispatch }, params) {
      try {
        const response = await fetchSeatOffersForParent(params);
        commit('setSeatOffersForParent', { seatOffersForParent: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve offers', error }, { root: true });
      }
    },
    async sendOffersMessage({ commit, dispatch }, seatId) {
      try {
        const response = await sendOffersMessage(seatId);
        commit('setSeatOfferMessageParams', { seatOfferMessageParams: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Cannot message this provider', error }, { root: true });
      }
    },
    async requestPaperwork({ dispatch }, seatId) {
      try {
        await requestPaperwork(seatId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Request was not successful. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw new Error();
      }
    },
    async updateWaitlistSpotAnswers({ dispatch }, { spotId, answers }) {
      try {
        await updateWaitlistSpotAnswers(spotId, answers);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update the waitlist spot answers', error }, { root: true });
      }
    },
    async acceptSeatOffer({ dispatch }, { seatId, paymentRequestMethods }) {
      try {
        await acceptSeatOffer({ seatId, paymentRequestMethods });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Request was not successful. Refresh the page to try again, or contact concierge@kinside.com for more help.', error }, { root: true });
        throw error;
      }
    },
    addDeclineOfferInfo({ commit }, declineInfo) {
      commit('setDeclineOfferInfo', declineInfo);
    },

    async fetchAgeGroups({ commit, dispatch }, { centerId }) {
      try {
        const response = await fetchAgeGroups(centerId);
        commit('setAgeGroups', { ageGroups: response });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve age groups', error }, { root: true });
      }
    },
  },
};
