// If using this component, you must import the mixin profileComponentMixin as well.
// import profileComponentMixin from '@profile/mixins/profile-component';

export default {
  data() {
    return {
      editTour: false,
      tourOpen: false,
    };
  },

  async mounted() {
    if (!this.facility?.id || this.facility.friendlyId !== this.friendlyId) {
      await this.loadFacility();
    }
    await this.loadUpcomingTour();
  },

  computed: {
    isLegupTourBooked() {
      return !!Object.keys(this.upcomingTour).length;
    },

    showContent() {
      return !this.loading && !!this.facility;
    },
    upcomingTour() {
      return this.$store.state.user.upcomingTour;
    },
    upcomingTourAgeGroupId() {
      return this.$store.state.user.upcomingTourAgeGroupId;
    },
  },

  methods: {
    openEditTour() {
      this.editTour = true;
      this.tourOpen = true;
    },

    tourModal(value) {
      if (!this.isAuthorized) {
        this.openAuthModal('tour');
        return;
      }

      this.tourOpen = value;
      if (this.editTour && !value) {
        this.editTour = false;
      }
    },

    updateTour({ tour, ageGroupId }) {
      this.$store.commit('user/upcomingTour', tour);
      this.$store.commit('user/upcomingTourAgeGroupId', ageGroupId);
    },

    onTourCancelled() {
      this.loadUpcomingTour();
    },

    async loadUpcomingTour() {
      if (!this.isAuthorized) {
        return;
      }

      if (!this.provider.currFacility) {
        await this.loadFacility();

        if (!this.provider.currFacility) {
          return;
        }
      }

      if (!this.$store.state.user.current.id) {
        await this.$store.dispatch('user/fetchCurrent');
      }
      const { legupCenterId, legupOnboarded } = this.provider.currFacility;

      if (!legupCenterId || !legupOnboarded) {
        return;
      }

      await this.$store.dispatch('tours/centerParentTour', { legupCenterId });
      this.$store.commit('user/upcomingTour', await this.$store.getters['tours/upcomingTour']);
      if (this.upcomingTour && this.upcomingTour.child) {
        await this.$store.dispatch('legup/dependentAgeGroup', {
          centerId: legupCenterId,
          childBirthDate: this.upcomingTour.child.birthDate,
          preferredDate: this.upcomingTour.preferredDate,
        });
        this.$store.commit('user/upcomingTourAgeGroupId', await this.$store.getters['legup/dependentAgeGroup']);
      } else {
        this.$store.commit('user/upcomingTourAgeGroupId', '');
      }
    },
  },
};
